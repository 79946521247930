var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "js-dialog-form js-system-aiedit",
      attrs: {
        title: _vm.type ? "人像识别编辑" : "车辆识别编辑",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        width: "560px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "ul",
        [
          _vm.type
            ? [
                _c(
                  "li",
                  [
                    _c("div", { staticClass: "label" }, [_vm._v("报警阈值：")]),
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择报警阈值", size: "small" },
                        model: {
                          value: _vm.params.threshold,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "threshold", $$v)
                          },
                          expression: "params.threshold",
                        },
                      },
                      _vm._l(_vm.thresholdOptions, function (item) {
                        return _c("el-option", {
                          key: item,
                          attrs: { label: item, value: item },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _c(
            "li",
            [
              _c("div", { staticClass: "label" }, [_vm._v("报警模式：")]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择报警模式", size: "small" },
                  model: {
                    value: _vm.params.pattern,
                    callback: function ($$v) {
                      _vm.$set(_vm.params, "pattern", $$v)
                    },
                    expression: "params.pattern",
                  },
                },
                _vm._l(_vm.modeOptions, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm.type
            ? [
                _c(
                  "li",
                  [
                    _c("div", { staticClass: "label" }, [_vm._v("清晰度：")]),
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择清晰度", size: "small" },
                        model: {
                          value: _vm.params.definition,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "definition", $$v)
                          },
                          expression: "params.definition",
                        },
                      },
                      _vm._l(_vm.definitionOptions, function (item) {
                        return _c("el-option", {
                          key: item,
                          attrs: { label: item, value: item },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "li",
                  [
                    _c("div", { staticClass: "label" }, [_vm._v("角度：")]),
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择角度", size: "small" },
                        model: {
                          value: _vm.params.angle,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "angle", $$v)
                          },
                          expression: "params.angle",
                        },
                      },
                      _vm._l(_vm.angleOptions, function (item) {
                        return _c("el-option", {
                          key: item,
                          attrs: { label: item, value: item },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleCancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }