<template>
  <div class="js-common-wrap js-system-wrap">
    <div class="js-common-head">
      <label>AI系统配置</label>
    </div>
    <div class="js-common-content">
      <div class="aiSystem-set">
        <div class="aiSystem-set-header">
          <label>人像识别</label>
          <el-button type="primary" size="small" @click="edit(1)">编辑</el-button>
        </div>
        <ul class="aiSystem-set-list">
          <li>
            <label>报警阈值：</label>
            <span>{{ faceSet.threshold }}%</span>
          </li>
          <li>
            <label>清晰度：</label>
            <span>{{ faceSet.definition }}</span>
          </li>
          <li>
            <label>角度：</label>
            <span>{{ faceSet.angle }}°</span>
          </li>
          <li>
            <label>报警模式：</label>
            <span>{{ getPattern(faceSet.pattern) }}</span>
          </li>
        </ul>
      </div>
      <div class="aiSystem-set">
        <div class="aiSystem-set-header">
          <label>车牌识别</label>
          <el-button type="primary" size="small" @click="edit(0)">编辑</el-button>
        </div>
        <ul class="aiSystem-set-list">
          <li>
            <label>报警模式：</label>
            <span>{{ getPattern(carSet.pattern) }}</span>
          </li>
        </ul>
      </div>
    </div>
    <system-edit v-model="editDialogVisible" :type="type" :currentItem="currentItem" @handleItem="handleEdit"></system-edit>
  </div>
</template>
<script>
import SystemEdit from '@/components/SystemAiEdit'
export default {
  name: '',
  components: {
    SystemEdit
  },
  data() {
    return {
      that: this,
      type: 1,
      editDialogVisible: false,
      faceSet:{
        angle: '',
        definition: '',
        pattern: '',
        threshold: ''
      },
      carSet:{
        pattern: ''
      },
      modeOptions: [{
        label: '黑名单',
        value: '0'
      },{
        label: '白名单',
        value: '1'
      },{
        label: 'VIP',
        value: '2'
      }],
      currentItem: ''
    }
  },
  mounted() {
    this._initPage()
  },
  methods: {
    _initPage(){
      this.getFaceSet(true)
      this.getFaceSet(false)
    },
    // 过滤报警模式
    getPattern(value){
      let item = this.modeOptions.find((item)=>{
        return item.value == value
      })
      if(item){
        return item.label
      }else{
        return ''
      }
    },
    getFaceSet(type){
      let url = ''
      if(type){
        url = 'api/csp/afr/v1/afrConfig/'
      }else{
        url = 'api/csp/lpr/v1/lprConfig/'
      }
      this.$axios({
        method: 'get',
        url: url
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          if(type){
            this.faceSet = data.data
          }else{
            this.carSet = data.data
          }
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    },
    edit(type){
      this.type = type
      if(type){
        this.currentItem = this.$Utils.deepCopy(this.faceSet)
      }else{
        this.currentItem = this.$Utils.deepCopy(this.carSet)
      }
      this.editDialogVisible = true
      console.log(this.currentItem)
    },
    handleEdit(params){
      let url = '',data = ''
      if(this.type){
        url = 'api/csp/afr/v1/afrConfig/save'
        data = {
          angle: params.angle.toString(),
          definition: params.definition.toString(),
          pattern: params.pattern.toString(),
          threshold: params.threshold.toString()
        }
      }else{
        url = 'api/csp/lpr/v1/lprConfig/save'
         data = {
          pattern: params.pattern.toString()
        }
      }
      this.$axios({
        method: 'post',
        url: url,
        data: data
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.editDialogVisible = false
          this.$message({
            type: 'success',
            message: '编辑成功！',
            duration: 1000
          })
          this._initPage()
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/system.scss'
</style>
