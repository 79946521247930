<template>
  <el-dialog class="js-dialog-form js-system-aiedit" :title="type?'人像识别编辑':'车辆识别编辑'" :visible.sync="dialogVisible" :close-on-click-modal="false" width="560px">
    <ul>
      <template v-if="type">
        <li>
          <div class="label">报警阈值：</div>
          <el-select v-model="params.threshold" placeholder="请选择报警阈值" size="small">
            <el-option v-for="item in thresholdOptions" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </li>
      </template>
      <!-- <li class="label">报警模式：</li> -->
      <li>
        <div class="label">报警模式：</div>
        <el-select v-model="params.pattern" placeholder="请选择报警模式" size="small">
          <el-option v-for="item in modeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </li>
      <template v-if="type">
        <!-- <li class="label">清晰度：</li> -->
        <li>
          <div class="label">清晰度：</div>
          <el-select v-model="params.definition" placeholder="请选择清晰度" size="small">
            <el-option v-for="item in definitionOptions" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </li>
        <!-- <li class="label">角度：</li> -->
        <li>
          <div class="label">角度：</div>
          <el-select v-model="params.angle" placeholder="请选择角度" size="small">
            <el-option v-for="item in angleOptions" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </li>
      </template>
    </ul>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel" size="small">取 消</el-button>
      <el-button type="primary" @click="handleConfirm" size="small">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: '',
  props: ['value','type','currentItem'],
  data() {
    return {
      title: '',
      thresholdOptions: [0,5,10,15,20,25,30,35,40,45,50,55,60,65,70,75,80,85,90,95,100],
      modeOptions: [{
        label: '黑名单',
        value: '0'
      },{
        label: '白名单',
        value: '1'
      },{
        label: 'VIP',
        value: '2'
      }],
      definitionOptions: [0,5,10,15,20,25,30,35,40,45,50],
      angleOptions: [0,5,10,15,20,25,30,35,40,45,50,55,60,65,70,75,80,85,90]
    }
  },
  computed: {
    dialogVisible: {
      get(){
        return this.value
      },
      set(newVal){
        this.$emit('input', newVal)
      }
    },
    params(){
      return this.currentItem
    }
  },
  mounted() {},
  methods: {
    handleCancel(){
      this.dialogVisible = false
    },
    handleConfirm(){
      this.$emit('handleItem',this.params)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/system.scss'
</style>