var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "js-common-wrap js-system-wrap" },
    [
      _vm._m(0),
      _c("div", { staticClass: "js-common-content" }, [
        _c("div", { staticClass: "aiSystem-set" }, [
          _c(
            "div",
            { staticClass: "aiSystem-set-header" },
            [
              _c("label", [_vm._v("人像识别")]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.edit(1)
                    },
                  },
                },
                [_vm._v("编辑")]
              ),
            ],
            1
          ),
          _c("ul", { staticClass: "aiSystem-set-list" }, [
            _c("li", [
              _c("label", [_vm._v("报警阈值：")]),
              _c("span", [_vm._v(_vm._s(_vm.faceSet.threshold) + "%")]),
            ]),
            _c("li", [
              _c("label", [_vm._v("清晰度：")]),
              _c("span", [_vm._v(_vm._s(_vm.faceSet.definition))]),
            ]),
            _c("li", [
              _c("label", [_vm._v("角度：")]),
              _c("span", [_vm._v(_vm._s(_vm.faceSet.angle) + "°")]),
            ]),
            _c("li", [
              _c("label", [_vm._v("报警模式：")]),
              _c("span", [_vm._v(_vm._s(_vm.getPattern(_vm.faceSet.pattern)))]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "aiSystem-set" }, [
          _c(
            "div",
            { staticClass: "aiSystem-set-header" },
            [
              _c("label", [_vm._v("车牌识别")]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.edit(0)
                    },
                  },
                },
                [_vm._v("编辑")]
              ),
            ],
            1
          ),
          _c("ul", { staticClass: "aiSystem-set-list" }, [
            _c("li", [
              _c("label", [_vm._v("报警模式：")]),
              _c("span", [_vm._v(_vm._s(_vm.getPattern(_vm.carSet.pattern)))]),
            ]),
          ]),
        ]),
      ]),
      _c("system-edit", {
        attrs: { type: _vm.type, currentItem: _vm.currentItem },
        on: { handleItem: _vm.handleEdit },
        model: {
          value: _vm.editDialogVisible,
          callback: function ($$v) {
            _vm.editDialogVisible = $$v
          },
          expression: "editDialogVisible",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "js-common-head" }, [
      _c("label", [_vm._v("AI系统配置")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }